import "styles/pages/products.scss"

import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"

const Products = ({ data }) => {
  const title = "Vørur"

  return (
    <Layout>
      <Seo title={title} />
      <PageHeader title={title} />
      <Breadcrumbs title={title} />

      <section className="products">
        <div className="container-fluid">
          <div className="row">
            {data.wpPage.acfProducts.productsGrid.map((item, index) => (
              <div
                className={
                  item.productTile === "wide" ? "col-md-8" : "col-md-4"
                }
                key={index}
              >
                <Link to={`/${item.productSlug}/`} className="products__tile">
                  <div className="products__tile-image">
                    <img src={item.productImage.sourceUrl} alt="" />
                  </div>
                  <p className="products__tile-title">{item.productTitle}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoxMjMy" }) {
      id
      title
      acfProducts {
        productsGrid {
          productTile
          productTitle
          productSlug
          productImage {
            sourceUrl
          }
        }
      }
    }
  }
`

export default Products
